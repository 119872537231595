import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Table from "components/common/Table";
import { TableContent } from "components/common/TableContent";
import moment from "moment";
import EmptyChargebackIcon from "assets/images/icons/refund.svg";
import CustomTableFilter from "components/common/CustomTableFilter";
import Pagination from "components/common/Pagination";
import SearchBox from "components/common/SearchBox";
import { Chargeback } from "types/refund";
import ChargebackDetailModal from "./ChargebackDetailModal";
import useToolkit from "hooks/useToolkit";
import { RootState } from "store/store";
import { getAllChargebacks } from "store/chargebacks/action";
import useUrlQueryParams from "utils/useUrlQueryParams";
import processParams from 'utils/processParams';
import EmptyUI from "components/common/EmptyUi";


export const ChargebacksTable = () => {
  const { dispatch, useSelector } = useToolkit();
  const [listParams, setListParams] = useState({
    "page": 1,
    "limit": 9,
  });

  const {
    chargebacksData: data,
    chargebacksLoading: listLoading
  } = useSelector((state: RootState) => state.chargebacks);

  const location = useLocation();
  const { urlQueryParams } = useUrlQueryParams();
  const [selected, setSelected] = useState<any>(null);
  const [isModalOopen, setIsModalOopen] = useState(false);

  const handleClick = (item: Chargeback) => {
    setSelected(item)
    setIsModalOopen(true);
  };

  const getChargebacks = () => {
    let params = { ...listParams };

    params = processParams(urlQueryParams, params, "page", "number", 1, "page");
    params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
    params = processParams(urlQueryParams, params, "status", "string", "", "status");
    params = processParams(urlQueryParams, params, "due", "string", "", "due");

    setListParams(params);
    dispatch(getAllChargebacks(params));
  }

  useEffect(() => {
    getChargebacks();
    // eslint-disable-next-line
  }, [location]);

  return (
    <div>
      <FilterPaginateSearch data={data} />

      <Table>

        <Table.Head>
          <th>CUSTOMER’S NAME</th>
          <th>Amount</th>
          <th>Date Created</th>
          <th>Due</th>
          <th>Status</th>
        </Table.Head>

        <Table.Body>
          <TableContent
            colspan={6}
            loading={listLoading}
            data={data?.data?.chargebacks}
            loadingText="Fetching chargebacks"
            total={data?.data?.chargebacks?.length || 0}
            emptyUI={
              <EmptyUI
                icon={EmptyChargebackIcon}
                header="No Chargebacks yet"
                subheader="There is no chargebacks at this time on your business account"
              />
            }
          />

          {listLoading === false && data?.data?.chargebacks && data?.data?.chargebacks?.map((item: any, idx: number) => {
            const isPending = item?.status === 'AWAITING_FEEDBACK';
            const isSuccess = item?.status === 'ACCEPTED';
            const isFailed = item?.status === 'DECLINED';

            return (
              <tr
                key={idx}
                style={{ cursor: "pointer" }}
                onClick={() => handleClick(item)}>
                <td>
                  {item?.customerName}
                </td>

                <td>
                  USD&nbsp;{item?.amount}
                </td>

                <td>
                  {moment(item?.createdAt).format('MMM D, YYYY [at] h:mma')}
                </td>

                <td>
                  {item?.due}
                </td>

                <td>
                  <div>
                    <div className={`border rounded-md py-1 px-[5px] flexed w-max text-[12px] capitalize font-inter font-medium 
                    ${isSuccess ? 'border-shiga-green-50 text-shiga-green-100'
                        : isPending ? 'border border-shiga-gray-75'
                          : isFailed ? 'border-shiga-red-40 text-shiga-red-100'
                            : ''
                      }`}>
                      {isPending ? 'Awaiting feedback' : item?.status?.toLowerCase()}
                    </div>
                  </div>
                </td>

              </tr>
            );
          })}
        </Table.Body>
      </Table>

      <ChargebackDetailModal
        selected={selected}
        sessionModalOpen={isModalOopen}
        fetchChargebacks={getChargebacks}
        setSessionModalOpen={setIsModalOopen}
      />
    </div>
  );
};

const FilterPaginateSearch = ({ data }: { data: any }) => {
  return (
    <div className="flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap">
      <div className="w-full lg:w-2/4">
        <CustomTableFilter
          dropdownClass=""
          filters={[
            {
              title: "Status",
              name: "status",
              dataType: "select",
              options: [
                {
                  display: "Show all",
                  payload: "-",
                },
                {
                  display: "Awaiting feedback",
                  payload: "AWAITING_FEEDBACK",
                },
                {
                  display: "Accepted",
                  payload: "ACCEPTED",
                },
                {
                  display: "Declined",
                  payload: "DECLINED",
                },
              ],
            },
            {
              title: "DUE",
              name: "due",
              dataType: "select",
              options: [
                {
                  display: "Show all",
                  payload: "-",
                },
                {
                  display: "In Days",
                  payload: "days",
                },
                {
                  display: "In Hours",
                  payload: "hours",
                },
                {
                  display: "In Minutes",
                  payload: "minutes",
                },
              ],
            },
          ]}
        />
      </div>

      <div className="w-full lg:w-2/4 flex items-center justify-between pt-5 md:pt-0 lg:space-x-5 lg:justify-end">
        <Pagination meta={data?.data?.meta} />

        <div className="lg:pb-[26px]">
          <SearchBox
            name="search"
            extraUrlParams="page=1"
            placeholder="Search chargebacks"
          />
        </div>
      </div>
    </div>
  );
};
