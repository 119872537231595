import { SetStateAction, useEffect, useState } from 'react'
import { RootState } from 'store/store';
import useToolkit from 'hooks/useToolkit';
import CenterModal from 'components/common/CenterModal'
import { ReactComponent as IconInfo } from "assets/images/icons/info-round-gray.svg";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { Input } from 'components/common/form';
import ShigaButton from 'components/common/form/ShigaButton';

type Props = {
   isOpen: boolean;
   setIsOpen: React.Dispatch<SetStateAction<boolean>>;
   handleRegen: (password: string) => void;
   selected: any;
}

const OperatorTokenModal = ({ isOpen, setIsOpen, handleRegen, selected }: Props) => {
   const { useSelector } = useToolkit();
   const [form, setForm] = useState({ password: '' })
   const { operatorTokenLoading: isLoading, operatorTokenData } = useSelector((state: RootState) => state.terminals)

   const handleSubmit = (e: any) => {
      e.preventDefault();
      handleRegen(form.password);
   }

   useEffect(() => {
      setForm({ password: '' })
   }, [])


   useEffect(() => {
      if (operatorTokenData !== null) {
         setForm({ password: '' })
      }
   }, [operatorTokenData])

   return (
      <CenterModal
         noHeader
         headerTitle=''
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         isButtonLoading={false}>

         <div className='w-full relative'>

            <div className="w-full flex-between items-start absolute -top-3.5">
               <IconInfo />

               <button onClick={() => setIsOpen(false)}
                  className="w-8 h-8 bg-shiga-gray-40 flex rounded-full">
                  <IconClose className="m-auto svg-stroke-gray" />
               </button>
            </div>

            <div className="min-h-[150px] pt-16">

               <p className="font-medium lg:text-2xl font-ojah mb-5">
                  Generate new Login token
               </p>

               <p className="text-shiga-dark-100 text-lg border-b border-shiga-gray-50 pb-4">
                  Performing this action will terminate the current login token used for
                  <span className="font-medium">
                     &nbsp;{selected?.operatorId}.
                  </span>
                  <br />
                  <br />

                  Please note that this action cannot be reversed.
               </p>

            </div>

            <form onSubmit={handleSubmit}>

               <div className="pb-3 border-b border-shiga-gray-50">
                  <Input
                     required={true}
                     type="password"
                     value={form.password}
                     placeholder='Enter password'
                     label='Enter your account password to confirm'
                     onChange={(e: any) => setForm({ ...form, password: e.target.value })}
                  />
               </div>

               <div className="mt-5">
                  <ShigaButton
                     danger
                     fullWidth
                     type='submit'
                     text="Regenerate Token"
                     loading={isLoading}
                     disabled={form?.password?.length < 8}
                  />
               </div>
            </form>
         </div>

      </CenterModal>
   )
}

export default OperatorTokenModal