import ObjectToQueryString from "utils/ObjectToQueryString";
import * as actions from "./actionTypes";
import * as config from "data/config";


export function getDeviceTrxDetails({ id, tab }: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetDeviceTrxDetails}${id}/terminals?tab=${tab}`,
            method: "get",
            data: {},
            onStart: actions.GET_DEVICE_TRX_DETAILS_START,
            onSuccess: actions.GET_DEVICE_TRX_DETAILS_DONE,
            onError: actions.GET_DEVICE_TRX_DETAILS_FAILED
        }
    }
}


export function getDeviceTrx(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetTerminalDeviceTrx + ObjectToQueryString(payload),
            method: "get",
            data: {},
            onStart: actions.GET_DEVICE_TRX_START,
            onSuccess: actions.GET_DEVICE_TRX_DONE,
            onError: actions.GET_DEVICE_TRX_FAILED
        }
    }
}

export function getTerminalDeviceMetrics(id: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetTerminalDeviceTrx}${id}/metrics`,
            method: "get",
            data: {},
            onStart: actions.GET_DEVICE_TRX_METRICS_START,
            onSuccess: actions.GET_DEVICE_TRX_METRICS_DONE,
            onError: actions.GET_DEVICE_TRX_METRICS_FAILED
        }
    }
}

export function getTerminalDeviceTrx({ id, params }: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetTerminalDeviceTrx}${id}/transactions${ObjectToQueryString(params)}`,
            method: "get",
            data: {},
            onStart: actions.GET_DEVICE_TRX_START,
            onSuccess: actions.GET_DEVICE_TRX_DONE,
            onError: actions.GET_DEVICE_TRX_FAILED
        }
    }
}

export function deleteTerminalAgent({ id, password }: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGenerateAgentToken + id,
            method: "delete",
            data: { password },
            onStart: actions.DELETE_TERMINAL_AGENT_START,
            onSuccess: actions.DELETE_TERMINAL_AGENT_DONE,
            onError: actions.DELETE_TERMINAL_AGENT_FAILED
        }
    }
}

export function getAllTerminalAgents(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllAgents + ObjectToQueryString(payload),
            method: "get",
            data: {},
            onStart: actions.GET_TERMINAL_AGENTS_START,
            onSuccess: actions.GET_TERMINAL_AGENTS_DONE,
            onError: actions.GET_TERMINAL_AGENTS_FAILED
        }
    }
}

export function createTerminalAgent({ password }: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCreateAgent,
            method: "post",
            data: { password },
            onStart: actions.CREATE_TERMINAL_AGENT_START,
            onSuccess: actions.CREATE_TERMINAL_AGENT_DONE,
            onError: actions.CREATE_TERMINAL_AGENT_FAILED
        }
    }
}

export function generateAgentToken({ id, password }: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGenerateAgentToken + id + '/token',
            method: "patch",
            data: { password },
            onStart: actions.GENERATE_AGENT_TOKEN_START,
            onSuccess: actions.GENERATE_AGENT_TOKEN_DONE,
            onError: actions.GENERATE_AGENT_TOKEN_FAILED
        }
    }
}

export function generateOperatorToken({ id, password }: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGenerateOperatorToken + id + '/token',
            method: "patch",
            data: { password },
            onStart: actions.GENERATE_OPERATOR_TOKEN_START,
            onSuccess: actions.GENERATE_OPERATOR_TOKEN_DONE,
            onError: actions.GENERATE_OPERATOR_TOKEN_FAILED
        }
    }
}

export function deleteTerminalOperator({ id, password }: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiDeleteTerminalOperator + id,
            method: "delete",
            data: { password },
            onStart: actions.DELETE_TERMINAL_OPERATOR_START,
            onSuccess: actions.DELETE_TERMINAL_OPERATOR_DONE,
            onError: actions.DELETE_TERMINAL_OPERATOR_FAILED
        }
    }
}

export function getTerminalOperators(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetTerminalOperators + ObjectToQueryString(payload),
            method: "get",
            data: {},
            onStart: actions.GET_TERMINAL_OPERATORS_START,
            onSuccess: actions.GET_TERMINAL_OPERATORS_DONE,
            onError: actions.GET_TERMINAL_OPERATORS_FAILED
        }
    }
}

export function createTerminalOperator({ id, password }: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCreateTerminalOperator + id,
            method: "post",
            data: { password },
            onStart: actions.CREATE_TERMINAL_OPERATOR_START,
            onSuccess: actions.CREATE_TERMINAL_OPERATOR_DONE,
            onError: actions.CREATE_TERMINAL_OPERATOR_FAILED
        }
    }
}


export function deleteTerminalDevice({ id, password }: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateTerminalDevice + id,
            method: "delete",
            data: { password },
            onStart: actions.DELETE_TERMINAL_DEVICE_START,
            onSuccess: actions.DELETE_TERMINAL_DEVICE_DONE,
            onError: actions.DELETE_TERMINAL_DEVICE_FAILED
        }
    }
}

export function toggleTerminalDevice({ id, status }: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateTerminalDevice + id,
            method: "patch",
            data: { status },
            onStart: actions.UPDATE_TERMINAL_DEVICE_START,
            onSuccess: actions.UPDATE_TERMINAL_DEVICE_DONE,
            onError: actions.UPDATE_TERMINAL_DEVICE_FAILED
        }
    }
}

export function createTerminalDevice(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCreateTerminalDevice,
            method: "post",
            data: payload,
            onStart: actions.CREATE_TERMINAL_DEVICE_START,
            onSuccess: actions.CREATE_TERMINAL_DEVICE_DONE,
            onError: actions.CREATE_TERMINAL_DEVICE_FAILED
        }
    }
}

export function getDeviceDetails(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetDeviceDetails + ObjectToQueryString(payload),
            method: "get",
            data: {},
            onStart: actions.GET_DEVICE_DETAILS_START,
            onSuccess: actions.GET_DEVICE_DETAILS_DONE,
            onError: actions.GET_DEVICE_DETAILS_FAILED
        }
    }
}

export function getTerminalDevices(payload: any) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetTerminalDevices + ObjectToQueryString(payload),
            method: "get",
            data: {},
            onStart: actions.GET_TERMINAL_DEVICES_START,
            onSuccess: actions.GET_TERMINAL_DEVICES_DONE,
            onError: actions.GET_TERMINAL_DEVICES_FAILED
        }
    }
}

export function resetTerminalParams() {
    return {
        type: actions.RESET_TERMINAL_PARAMS,
    }
}